<template>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
        </ul>

        <div class="tab-content">
          <div class="tab-pane active">
            <div class="panel-body">
              <fieldset>

                <div class="form-group row"><label class="col-sm-2 col-form-label">#</label>
                  <div class="col-sm-10">
                    <input v-model="data['uid']" class="form-control" type="text" name="uid" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Store Name</label>
                  <div class="col-sm-10">
                    <search-select
                      v-model="selectedItem"
                      placeholder="변경할 상점 선택"
                      class="form-control"
                      name="store"
                      :is-disabled="data['status'] !== 'PAID'"
                      :options="items"
                      @searchchange="onChange" >
                    </search-select>
                    <span class="form-text"></span>

                    <button v-if="data['status'] === 'PAID'" class="btn btn-warning btn-sm" :disabled="sending" @click="onClickChangeStore">상점변경</button>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Reserve First Name</label>
                  <div class="col-sm-10">
                    <input :value="$utils.value(data, 'reserve.user_first_name')" class="form-control" type="text" name="reserve_first_name" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Reserve Last Name</label>
                  <div class="col-sm-10">
                    <input :value="$utils.value(data, 'reserve.user_last_name')" class="form-control" type="text" name="reserve_last_name" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">Merchant UID</label>
                  <div class="col-sm-10">
                    <input v-model="data['merchant_uid']" class="form-control" type="text" name="merchant_uid" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div> -->

                <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">IMP UID</label>
                  <div class="col-sm-10">
                    <input v-model="data['imp_uid']" class="form-control" type="text" name="imp_uid" :readonly="data['status'] !== 'PROGRESS'" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div> -->

                <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">Payment Status</label>
                  <div class="col-sm-10">
                    <input :value="$utils.value(data, 'payment.status')" class="form-control" type="text" name="pament_status" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Cancel Amount</label>
                  <div class="col-sm-10">
                    <input :value="$utils.value(data, 'payment.cancel_amount')" class="form-control" type="text" name="payment_cancel_amount" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div> -->

                <div class="form-group row"><label class="col-sm-2 col-form-label">Receipt Status</label>
                  <div class="col-sm-10">
                    <input v-model="data['status']" class="form-control" type="text" name="status" readonly="readonly" />
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

              </fieldset>

              <div class="form-group row">
                <div class="actions">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickCancel">Cancel</button>
                  </div>

                  <div class="right">
                    <button v-if="data['status'] === 'PAID'" class="btn btn-danger btn-sm" :disabled="sending" @click="onClickReserveCancel">예약취소</button>
                    <button v-if="data['status'] === 'PROGRESS'" class="btn btn-success btn-sm" :disabled="sending" @click="onClickReserveComplete">예약완료</button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import config from "@/config"
import Editor from "@/components/utils/Editor"
import ExtraEditor from "@/components/utils/ExtraEditor"
import ResourceEditor from "@/components/utils/ResourceEditor"

export default {
  data() {
    return {
      mode: 'unset',
      model: {},
      data: {},
      sending: false,
      keyword: '',
      selectedItem: { value: '', text: '' },
      items: []
    }
  },
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model

    this.loadData()
  },
  watch: {
    data(value, oldValue) {

    }
  },
  computed: {

  },
  methods: {
    loadData() {
      this.$store.dispatch('receipt/get', this.$route.params)
        .then((data) => {
          let { store_name, store_id } = data
          let selectedItem = {
            text: store_name,
            value: store_id
          }
          this.$data.data = data
          this.$data.items = [selectedItem]
          this.$data.selectedItem = selectedItem
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    loadItems(options = {}) {
      let { page, size, keyword } = options
      this.$data.keyword = keyword || this.keyword

      this.$store.dispatch('store/list', { page: 1, size: 5, filter: 'all', keyword: this.keyword })
        .then((data) => {
          let { items, total } = data
          let searchItems = this._.map(items, (item) => {
            return {
              text: item.store_name,
              value: item.uid
            }
          })

          this.$data.items = searchItems
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onChange(keyword) {
      this.loadItems({
        keyword: keyword
      })
    },

    onClickCancel(e) {
      this.$router.push({
        name: this.model.views.list.name,
        params: {}
      })
    },

    onClickChangeStore() {
      this.$dialog
        .confirm(`선택된 ${this.selectedItem.text} ( id: ${this.selectedItem.value} ) 상점으로 예약을 변경할까요?`, {
          okText: 'Confirm',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onChangeStore()
        })
        .catch(() => {

        })
    },

    onChangeStore() {
      let params = {
        uid: this.$route.params.uid + '',
        store_id: this.selectedItem.value + ''
      }

      this.$data.sending = true
      this.$store.dispatch('receipt/change', params)
        .then((data) => {
          this.$toast.success("변경되었습니다.")

          this.$data.keyword = ''
          this.$data.selectedItem = { text: '', value: '' }
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    },

    onClickReserveComplete() {
      this.$dialog
        .confirm(`${this.data.uid} 예약을 완료 처리할까요?`, {
          okText: 'Confirm',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onComplete()
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onComplete() {
      let params = {
        uid: this.$route.params.uid,
        imp_uid: this.data.imp_uid
      }

      this.$data.sending = true
      this.$store.dispatch('receipt/complete', params)
        .then((data) => {
          this.$toast.success("완료처리되었습니다.")
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    },

    onClickReserveCancel() {
      this.$dialog
        .confirm(`${this.data.uid} 예약을 취소할까요?`, {
          okText: 'Confirm',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onCancel()
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onCancel() {
      let params = {
        uid: this.$route.params.uid
      }

      this.$data.sending = true
      this.$store.dispatch('receipt/cancel', params)
        .then((data) => {
          this.$toast.success("취소되었습니다.")
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    }
  },
  components: {
    Editor, ExtraEditor, ResourceEditor
  }
}
</script>
